<template>
  <div class="mod-investor">
    <amis-renderer :schema="schema" />
  </div>
</template>

<script>
import AmisRenderer from '@/components/AmisRenderer';
import schema from './schemas/investor.json';

export default {
  name: 'Investor',
  components: { AmisRenderer },
  data() {
    return {
      schema,
    };
  },
};
</script>

<style lang="scss" scoped></style>
